export default [
  {
    path: 'setting/password',
    name: 'settingPassword',
    component: () => import('./password.vue')
  },

  {
    path: 'setting/subuser',
    name: 'settingSubuser',
    component: () => import('./subuser/subuser.vue'),
    meta: {
      name: '企业子账户',
      isTag: true,
      icon: require('/src/assets/user06.svg'),
      icons: require('/src/assets/user06_show.svg')
    }
  }
];
