export default [
  {
    path: 'grant/index',
    component: resolve => require(['./index'], resolve),
    meta: {
      name: '发放列表',
      isTag: true,
      icon: require('/src/assets/invoice.svg'),
      icons: require('/src/assets/invoice_show.svg')
    }
  },
  {
    path: 'grant/detail',
    component: resolve => require(['./detail'], resolve),
    meta: {
      name: '发放详情',
    }
  },
];