import Vue from 'vue';
import moment from 'moment';
import * as _ from 'lodash';
import { getPreloadData } from '../lib/global';
// 结算中心
// Vue.filter('platformVal', function(val) {
//   const map = {};
//   const platformList = getPreloadData('platformList');
//   platformList.forEach(item => {
//     map[item.pay_platform] = item.shortname;
//   });
//   return map[val] || '-';
// });

// 角色
Vue.filter('rolesVal', function(val) {
  const map = {};
  const roleList = getPreloadData('roleList');
  roleList.forEach(item => {
    map[item.alias] = item.name;
  });
  return map[val] || '-';
});

// 企业认证状态
Vue.filter('enterpriseCertiState', function(val) {
  // "0"：未认证 "1"：打款中  "2"：打款成功  "3"：认证成功 "4"认证失败
  const maps = ['未认证', '打款中', '打款成功', '认证成功', '认证失败'];
  return maps[val] || '';
});

// 合同分类
Vue.filter('contractTypeVal', function(val) {
  const maps = {
    '1': '劳动合同',
    '2': '临时劳动合同'
  };
  return maps[val] || '';
});

// 合同签署方
Vue.filter('subjectTypeVal', function(val) {
  const maps = ['甲方', '乙方', '丙方', '丁方'];
  return maps[val] || '';
});

// 交易卡类型
Vue.filter('cardTypeVal', function(val) {
  const map = {
    '1': '个人',
    '2': '企业'
  };
  return map[val] || '';
});

// 合同状态
Vue.filter('contractStateVal', function(val) {
  // const maps = {
  //   0: "草稿",
  //   2: "签署中",
  //   3: "签署完成",
  //   4: "签署失败"
  // };
  const maps = {
    1: '草稿',
    2: '发起中',
    3: '签署中',
    4: '签署成功',
    5: '签署失败',
    6: '签署过期',
    7: '已作废'
  };
  return maps[val] || '草稿';
});

// 支付状态
// "0支付中, 1成功, 2失败" 
Vue.filter('payStateVal', function(val) {
  const map = {
    '0': '支付中',
    '1': '成功',
    '2': '失败',
   
  };
  return map[val] || '';
});
Vue.filter('dateStateVal', function(val) {
  const map = {
    'today': '今日新增',
    'yesterday': '昨日新增',
    'week': '近7日',
    'all':'平台累计总量'
   
  };
  return map[val] || '';
});


// 开票状态
Vue.filter('billingStateVal', function(val) {
  const billingMap = {
    0: '未开票',
    1: '开票中',
    2: '已完成'
  };
  return billingMap[val] || '-';
});

// 工资包状态    // "1审核中 2复核中 3被驳回 4已完成 5已撤销"
Vue.filter('taskStateVal', function(val) {
  const taskStateMap = {
    0:'待核查',
    1: '审核中',
    2: '复核中',
    3: '被驳回',
    4: '已完成',
    5: '已撤销',
    
  };
  return taskStateMap[val] || '-';
});

// 验收单状态 0 待验收 1验收中 2 已验收 3待结算 4 已结算
Vue.filter('checkStateVal', function(val) {
  const taskStateMap = {
    0: '待验收',
    1: '验收中',
    2: '已验收',
    3: '待结算',
    4: '已结算'
  };
  return taskStateMap[val] || '-';
});

// 验收单状态  1-已报名 2-已取消 3-进行中 4-已完成 5-验收中 6-待结算 7-已结算
Vue.filter('registrationStateVal', function(val) {
  const taskStateMap = {
    1: '已报名',
    2: '已取消',
    3: '进行中',
    4: '已完成',
    5: '验收中',
    6: '待结算',
    7: '已结算'
  };
  return taskStateMap[val] || '-';
});

// 招聘状态   1-草稿 2-已发布 3-已关闭
Vue.filter('recruitStateVal', function(val) {
  const stateMap = {
    1: '草稿',
    2: '已发布',
    3: '已关闭'
  };
  return stateMap[val] || '-';
});

// 报名状态   1-待审核 2-已审核 3-已入职
Vue.filter('signupStateVal', function(val) {
  const stateMap = {
    1: '待审核',
    2: '已审核',
    3: '已入职'
  };
  return stateMap[val] || '-';
});
// 时间格式化
Vue.filter('dateVal', function(val, formate) {
  return moment(val).format(formate || 'YYYY-MM-DD HH:mm:ss');
});
// 角色处理
Vue.filter('roleVal', function(val) {
  const map = {
    platform: '平台管理员',
    enterprise_admin: '企业管理员',
    maker: '创客',
    enterprise_finance: '放薪管家财务人员',
    enterprise_carLeader: '放薪管家车队长',
    agent_root: '一级代理',
    enterprise_agent: '代理商管理员',
    enterprise_agent_user: '代理商成员',
    platform_sx: '陕西平台',
    platform_finance: '财务人员',
    agentGroup: '代理分组',
    agentUser: '代理成员',
    agentDomain: '代理分组'
  };
  const _val = _.difference(val.split(','), ['1']).join(',');
  return map[_val] || '-';
});
