import axios from 'axios';
import config from '../../config/config';
import interceptors from './error';
// import {isPlainObject} from "lodash"
export const instance = axios.create({
  baseURL: config.baseUrl,
  timeout: 20000
});
const apiTimes = {};
instance.interceptors.response.use(interceptors);

export const api = (prefix, method = 'POST') => (path, action) => (
  data = {}
) => {
  // const newpath = `${prefix}/${path}`;
  const newpath = `${path}`;
  const oldTime = apiTimes[newpath] || 0;
  const repeat = data.$ignoreRepeat;
  data.$ignoreRepeat = undefined;
  // if(isPlainObject(data)){
  //   data=Object.keys(data).forEach(key=>{

  //   })
  // }

  if (!repeat && Date.now() - oldTime < 300) {
    throw new Error('点太快了');
  } else {
    apiTimes[newpath] = Date.now();
    return instance({
      url: newpath,
      method,
      ...(method.toUpperCase() === 'POST'
        ? {
            data: data
          }
        : method.toUpperCase() === 'GET'
        ? {
            params: data
          }
        : {}),
      headers: {
        Authorization: window.localStorage.getItem('token'),
        Action: action || ''
      }
    }).then(it => it.data.data || it.data);
  }
};

let avi =''
if (config.environment ==='development') {
 avi= (...path) =>`${config.baseUrl}/${path.join('/')}`;
}
else {
  avi=(...path) =>`${window.location.origin}/${path.join('/')}`;
}

export const generatePath  =avi

