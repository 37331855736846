export default [
  {
    path: 'service/index',
    component: resolve => require(['./List'], resolve),
    meta: {
      name: '服务包列表',
      isTag: true,
      icon: require('/src/assets/user01.svg'),
      icons: require('/src/assets/user01_show.svg')
    }
  },
  {
    path: 'service/add',
    component: resolve => require(['./add'], resolve),
    meta: {
      name: '新建服务包',
    }
  },
  {
    path: 'service/detail',
    component: resolve => require(['./detail'], resolve),
    meta: {
      name: '服务包详情',
    }
  },
  {
    path: 'service/serviceTemplate',
    component: resolve => require(['./serviceTemplate'], resolve),

    meta: {
      name: '工资单模板',
      isTag: true,
      // keepAlive:true,
      icon: require('/src/assets/package02.svg'),
      icons: require('/src/assets/package02_show.svg')
    }
  },
  {
    path: 'service/configuration',
    name: 'configuration',
    component: resolve => require(['./configuration.vue'], resolve),
    meta: {
      name: '参数配置',
      isTag: true,
      // keepAlive:true,
      icon: require('/src/assets/package02.svg'),
      icons: require('/src/assets/user03_show.svg')
    }
  },

  {
    path: 'service/addTemplate',
    component: resolve => require(['./addTemplate'], resolve),
    meta: {
      name: '新建工资单模板',
    }
  }
]