export default [
  {
    path: 'task/index',
    component: resolve => require(['./index'], resolve),
    meta: {
      name: '工资包',
      isTag: true,
      icon: require('/src/assets/package02.svg'),
      icons: require('/src/assets/package_show.svg')
    }
    // keepAlive: true
  },
  {
    path: 'task/add',
    component: resolve => require(['./Add'], resolve)
  },
  {
    path: 'task/detail',
    component: resolve => require(['./Detail'], resolve),
  },
  {
    path: 'task/addTaskTemplate',
    component: resolve => require(['./addTaskTemplate'], resolve),
  },
  {
    path: 'task/taskTemplate',
    component: resolve => require(['./taskTemplate'], resolve),
    meta: {
      name: '工资包',
      // keepAlive: true,
      icon: require('/src/assets/package02.svg'),
      icons: require('/src/assets/package02_show.svg')
    },
  },
  {
    path: 'task/list',
    component: resolve => require(['./List'], resolve),
    meta: {
      name: '工资包',
      // keepAlive: true,
      icon: require('/src/assets/package01.svg'),
      icons: require('/src/assets/package01_show.svg')
    },
    // beforeRouteEnter(to, from, next) {
    //   // 在渲染该组件的对应路由被 confirm 前调用
    //   // 不！能！获取组件实例 `this`
    //   // 因为当守卫执行前，组件实例还没被创建
    //   console.log(to, from, next);
    // },
  }
];
