<template>
  <div id="app">
    <EnvLabel />
    <router-view />
  </div>
</template>
<script>

import EnvLabel from './components/env.vue';

export default {
  data () {
    return {
      VUE_APP_NODE_ENV: process.env.VUE_APP_NODE_ENV
    };
  },
  created () { },
  methods: {},
  components: {
    [EnvLabel.name]: EnvLabel,
  }
};
</script>
<style lang="scss">
.left_top {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .left_icon {
    display: flex;
    align-items: center;
    span:nth-child(1) {
      padding: 8px 4px;
      background: #4977fc;
      margin-right: 10px;
    }
    span:nth-child(2) {
      font-size: 16px;
      font-weight: 700;
    }
  }
}
.shuxian {
  display: inline-block;
  margin-left: 5px;
}
.shuxian:last-child {
  div {
    display: none;
  }
}
.header {
  display: flex;
  justify-content: space-between;
  border: none;

  align-items: center;
  font-weight: 400;
  color: #606266;
  cursor: pointer;
  margin: 20px 0;
  .header_top {
    display: flex;
    align-items: center;
    img:nth-child(1) {
      width: 20px;
      height: 20px;
    }
    img:nth-child(3) {
      width: 9px;
      height: 10px;
    }
    span {
      margin: 0 10px;
      font-size: 16px;
      color: #606060;
    }
  }
}
.del-pop {
  display: flex;
  align-items: center;
  justify-content: center;
}
.del-title {
  font-size: 17px;
  text-align: center;
  color: #333333;
  margin: 15px 0;
}
.del-sumtitle {
  font-size: 13px;
  text-align: center;
  color: #a9a9a9;
  margin: 0 0 5px 0;
}
.del-btn {
  display: flex;
  align-items: center;
  justify-content: center;
}
.dev_label {
  position: fixed;
  background: red;
  color: #fff;
  left: 0;
  top: 0;
  font-size: 14px;
  font-weight: 600;
  z-index: 1000001;
}

* {
  padding: 0;
  margin: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  overflow: hidden;
  overflow-y: hidden;
}

.bottom_border {
  border-top: 2px solid #e6e6e6;
  padding: 10px;
  width: 1300px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;

  h4 {
    margin: 16px 0 30px 0;
    color: #588bb1;
  }
  .bottom_right {
    display: flex;
    align-items: end;
    justify-content: space-evenly;
    img {
      width: 40ox;
      height: 40px;
    }
    .name {
      color: #666666;
      font-size: 14px;
      margin-left: 5px;

      font-weight: 700;
    }
    .border_demo {
      width: 30px;
      height: 30px;
      margin: 0 16px;
      border-radius: 50%;
      background: #fff;
      text-align: center;
      line-height: 30px;
      color: #588bb1;
      font-weight: 700;
    }
  }
}
</style>
