export default [{
    path: 'trade',
    component: resolve => require(['./index'], resolve),
    meta: {
      name: '财务管理',
      isTag: true,
      icon: require('/src/assets/finance.svg'),
      icons: require('/src/assets/finance_show.svg')
    }
  },
  {
    path: 'trade/list',
    component: resolve => require(['./newList'], resolve),
    meta: {
      name: '交易管理',
      isTag: true,
      // keepAlive:false,
      icon: require('/src/assets/finance01.svg'),
      icons: require('/src/assets/finance01_show.svg')
    }
  },
  {
    path: 'trade/detail',
    component: resolve => require(['./Detail'], resolve)
  },
  {
    path: 'withdraw/list',
    component: resolve => require(['./withdraw/List'], resolve),
    meta: {
      name: '提现管理',
      isTag: true,
      keepAlive:false,
      icon: require('/src/assets/finance02.svg'),
      icons: require('/src/assets/finance02_show.svg')
    }
  },
  {
    path: 'withdraw/detail',
    component: resolve => require(['./withdraw/Detail'], resolve)
  },
  {
    path: 'trade/recharge',
    component: resolve => require(['./recharge'], resolve),
    meta: {
      name: '企业充值记录',
      isTag: true,
      // keepAlive:true,
      icon: require('/src/assets/finance05.svg'),
      icons: require('/src/assets/finance05_show.svg')
    }
  },
  {
    path: 'trade/userStatics',
    component: resolve => require(['./userStatics'], resolve),
    meta: {
      name: '创客累计流水记录',
      isTag: true,
      icon: require('/src/assets/finance03.svg'),
      icons: require('/src/assets/finance03_show.svg')
    }
  },
  {
    path: 'trade/userStatisticsInfo',
    component: resolve => require(['./userStatisticsInfo'], resolve),
    meta: {
      name: '用户统计详情',
      isTag: true
    }
  },
  {
    path: 'trade/enterpriseStatics',
    component: resolve => require(['./enterpriseStatics'], resolve),
    meta: {
      name: '企业累计流水记录',
      isTag: true,
      icon: require('/src/assets/finance04.svg'),
      icons: require('/src/assets/finance04_show.svg')
    }
  },
  {
    path: 'trade/enterpriseStaticsInfo',
    component: resolve => require(['./enterpriseStatisticsInfo'], resolve),
    meta: {
      name: '企业统计详情',
      isTag: true
    }
  }
];