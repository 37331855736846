export default [
  {
    path: 'enterpriseProject/index',
    component: resolve => require(['./index'], resolve),
    meta: {
      name: '项目管理',
      // isTag: true,
      // keepAlive:true,
      icon: require('/src/assets/invoice.svg'),
      icons: require('/src/assets/invoice_show.svg')
    }
  },
  {
    path: 'enterpriseProject/detail',
    component: resolve => require(['./detail'], resolve),
    meta: {
      name: '项目详情',
    }
  },
  {
    path: 'enterpriseProject/add',
    component: resolve => require(['./add'], resolve),
    meta: {
      name: '项目客户',
    }
  }
]