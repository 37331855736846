import { api } from '../api';


export const preload = [
  {
    name: 'login',
    data: null,
    reg: /^\/login/,
    load: async function() {
      const token = window.localStorage.getItem('token');
      if (token) {
        window.location.replace('/web/system/index/home');
      } else {
        return true;
      }
    }
  },

  {
    name: 'roleList',
    data: null,
    reg: /^\/system\//,
    load: async function() {
      this.data = this.data || (await api()('role.list.json')({type:'custom'})).list;
      return true;
    }
  },
  {
    name: 'touristInitData',
    data: null,
    reg: /.*/,
    load: async function() {
      // this.data = this.data || (await api("/access")("access.tourist.json")());
      return true;
    }
  }
];
export const getPreloadData = (key = '') => {
  const obj = {};
  preload.forEach(it => {
    obj[it.name] = it.data;
  });
  return obj[key] || obj;
};
export const setPreloadData = (name, data) => {
  const it = preload.find(it => it.name === name);
  if (it) {
    it.data = data;
  } else {
    preload.push({
      name,
      data
    });
  }
};
export const debounce = (func, delay = 200) => {
  let timeout = null
  return function () {
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      func.apply(this, arguments)
    }, delay)
  }
}
export const phoneReg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
export const idCardNumberReg = /^([1-6][1-9]|50)\d{4}(19|20)\d{2}(0[1-9]|10|11|12)(0[1-9]|1[0-9]|2[0-9]|3[0-1])\d{3}[0-9xX]$/;
export const enterpriseReg =/^.{2,8}$/;
export const userType = {
  CUSTOM: 'custom',
  SYSTEM: 'system'
};

export const withdrawalListResultMap = it => {
  return {
    account: it.outerTrade_fromAccount,
    account_name: it.outerTrade_accountName,
    amount: it.outerTrade_amount,
    bank_branch: it.outerTrade_branchBank,
    bank_code: it.outerTrade_accountCode,
    bank_line_no: it.outerTrade_branchCode,
    bank_name: it.outerTrade_bankName,
    code: it.outerTrade_code,
    create_time: it.outerTrade_createTime,
    delete: false,
    flowId: it.outerTrade_flowId,
    id: it.outerTrade_id,
    // idNumber: "430981199312252126",
    name: it.outerTrade_accountName,
    pay_platform: it.outerTrade_payPlatform,
    // pay_time: "2021-09-09 09:02:48",
    remark: it.outerTrade_remark,
    state: it.outerTrade_state,
    state_reason: it.outerTrade_stateReason,
    type: it.outerTrade_cardAttribute,
    filePath: it.outerTrade_fileName,
  };
};
export const tradeListResultMap = it => {
  return {
    amount: it.innerTrade_amount,
    // auto_withdrawal: false
    code: it.innerTrade_code,
    create_time: it.innerTrade_createTime,
    delete: it.innerTrade_delete,
    flowId: it.innerTrade_flowId,
    from_account: it.innerTrade_fromAccount,
    // from_id: it.innerTrade_id,
    from_name: it.innerTrade_fromName,
    // from_phone: ""
    // from_type: "1"
    id: it.innerTrade_id,
    link_class: it.innerTrade_link,
    // link_object: "ktcf11gr0"
    pay_platform: it.innerTrade_payPlatform,
    remark: it.innerTrade_remark,
    state: it.innerTrade_state,
    state_reason: it.innerTrade_stateReason,
    to_account: it.innerTrade_toAccount,
    // to_id: "91611103MA6TKW0HX1"
    to_name: it.innerTrade_toName
    // to_phone: ""
    // to_type: "1"
    // withdrawal_params: ""
  };
};
