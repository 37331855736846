// 服务端数据处理
import Vue from 'vue';
export default responese => {
  // 处理登陆过期
  if (responese.data.code === 401) {
    return Vue.prototype
      .$confirm('登录过期，请重新登录', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
      .then(() => {
        window.localStorage.removeItem('token');
        window.location.href = '/web/login?from=' + window.location.pathname;
      });
  }
  if (responese.data.code === 403) {
    // const data = new URLSearchParams(responese.data.message)
    return Vue.prototype
      .$alert(responese.data.message, '提示', {
        confirmButtonText: '返回',
        showClose:true,
        type: 'warning'
      })
      .then(() => {
         window.history.go(-1)
      })
  }
  
  // 处理其他错误
  if (responese.data?.code !== 0) {
    Vue.prototype.$message.warning(responese.data.message);
    throw new Error(responese.data.message);
  }
  return responese;
};
