export default [
  {
    path: 'invoice/index',
    component: resolve => require(['./index'], resolve),
    meta: {
      name: '发票管理',
      isTag: true,
      icon: require('/src/assets/invoice.svg'),
      icons: require('/src/assets/invoice_show.svg')
    }
  },
  {
    path: 'invoice/add',
    component: resolve => require(['./add'], resolve),
    meta: {
      name: '新建发票管理',
    }
  },
  {
    path: 'invoice/detail',
    component: resolve => require(['./detail'], resolve),
    meta: {
      name: '发票详情',
    }
  },
];
