export default [{
  path: 'customer',
  component: resolve => require(['./index'], resolve),
  meta: {
    name: '用户管理',
    isTag: true,
    icon: require('/src/assets/user.svg'),
    icons: require('/src/assets/user_show.svg')
  }
},
{
  path: 'user/owner-info',
  component: resolve => require(['./DetailUserInfo'], resolve),
  meta: {
    name: '用户管理',
    isTag: true,
    icon: require('/src/assets/user.svg'),
    icons: require('/src/assets/user_show.svg')
  }
},
{
  path: 'user/detail',
  component: resolve => require(['./DetailUserInfo'], resolve),
  meta: {
    name: '当前用户',
    isTag: true,
    icon: require('/src/assets/user01.svg'),
    icons: require('/src/assets/user01_show.svg')
  }
},
{
  path: 'user/personnelComparison',
  component: resolve => require(['./personnelComparison'], resolve),

  meta: {
    name: '创客信息对比',
    isTag: true,
    icon: require('/src/assets/user07.svg'),
    icons: require('/src/assets/user07_show.svg')
  }
},
{
  path: 'user/erleihu',
  component: resolve => require(['./erleihu'], resolve),

  meta: {
    name: '二类户列表',
    isTag: true,
    icon: require('/src/assets/user06.svg'),
    icons: require('/src/assets/user06_show.svg')
  }
},
{
  path: 'user/noEmployedList',
  component: resolve => require(['./noEmployedList'], resolve),
  meta: {
    name: '待入职员工',
    icon: require('/src/assets/user08.png'),
    icons: require('/src/assets/user08_show.png')
  }
},
{
  path: 'user/activeEmployees',
  component: resolve => require(['./activeEmployees'], resolve),
  meta: {
    name: '在职员工',
    icon: require('/src/assets/user09.png'),
    icons: require('/src/assets/user09_show.png')
  }
},
{
  path: 'user/pendingResignationList',
  component: resolve => require(['./pendingResignationList'], resolve),
  meta: {
    name: '待离职员工',
    icon: require('/src/assets/user10.png'),
    icons: require('/src/assets/user10_show.png')
  }
},
{
  path: 'user/noAllocated',
  component: resolve => require(['./noAllocated'], resolve),
  meta: {
    name: '待分配员工',
    icon: require('/src/assets/user12.png'),
    icons: require('/src/assets/user12_show.png')
  }
},
{
  path: 'user/resignationList',
  component: resolve => require(['./resignationList'], resolve),
  meta: {
    name: '离职员工',
    icon: require('/src/assets/user11.png'),
    icons: require('/src/assets/user11_show.png')
  }
},
{
  path: 'user/list',
  component: resolve => require(['./List'], resolve),
  meta: {
    name: '合作创客',
    isTag: true,
    // keepAlive:true,
    icon: require('/src/assets/user04.svg'),
    icons: require('/src/assets/user04_show.svg')
  }
},
{
  path: 'user/customerlist',
  component: resolve => require(['./customerUserList'], resolve),
  meta: {
    name: '企业用户',
    isTag: true,
    keepAlive: true,
    icon: require('/src/assets/user04.svg'),
    icons: require('/src/assets/user04_show.svg')
  }
},
{
  path: 'user/bmdList',
  component: resolve => require(['./bmdList'], resolve)
},
{
  path: '/',
  component: resolve => require(['./List'], resolve),
  meta: {
    keepAlive: true,
  }
}
];